import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 重定向
  { path: '/', redirect: '/index' },
  {
    path:'/index',
    name:'index',
    component: () => import('@/views/index'),
  },
  // 行业方案
  {
    path:'/industry',
    name:'industry',
    component: () => import('@/views/industry')
  },
  // 科学技术
  {
    path:'/scienTech',
    name:'scienTech',
    component: () => import('@/views/scienTech')
  },
  // 关于我们
  {
    path:'/aboutUs',
    name:'aboutUs',
    component: () => import('@/views/aboutUs')
  },
  //企业建站
  {
    path:'/enterprise',
    name:'enterprise',
    component: () => import('@/views/enterprise')
  },
  //全能定制
  {
    path:'/customization',
    name:'customization',
    component:() => import('@/views/customization')
  },
  //多场景在线商城
  {
    path:'/onlineStore',
    name:'onlineStore',
    component:() => import('@/views/onlineStore')
  },
  //通用小程序打包服务
  {
    path:'/applet',
    name:'applet',
    component:() => import('@/views/applet')
  },
  //营销策划
  {
    path:'/marketing',
    name:'marketing',
    component:() => import('@/views/marketing')
  }
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === "development" ? '' : '/',
  // base: '/go20/test/gw/',
  routes,
  scrollBehavior (to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
    return { x: 0, y: 0 }
  }
})

export default router
