<template>
<div>
    <div class="topMod">
        <!-- PC端头部 -->
        <div class="topMod-box">
            <div class="w-1200">
                <div class="topMod_l" @click="home">
                    <div class="topMod_l_logo">
                        <img src="@/assets/image/logo.png" alt="">
                    </div>
                    <div class="topMod_l_h">峰盛信息科技</div>
                    <div class="topMod_l_qiye"><a href="https://open.work.weixin.qq.com"
                    target="_blank"><img src="https://open.work.weixin.qq.com/service/img?id=ww1cf6d40166cbc67a&t=isp&c=white&s=small"
                  srcset="https://open.work.weixin.qq.com/service/img?id=ww1cf6d40166cbc67a&t=isp&c=white&s=small@2x 2x"
                  referrerpolicy="unsafe-url"
                  alt="企业微信"></a></div>
                </div>
                <div class="topMod_c" >
                    <ul class="topMod_c_ul">
                        <li :class="{'active': index == isSelect}" v-for="(item,index) in arrList" :key="index" @click="bindColor(index,item.path)">
                            <div v-if="index==1">
                                    <span class="el-dropdown-link">
                                        服务领域<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <div  class="service">
                                        <div class="secondNav">
                                            <ul>
                                                <li v-for="(item,index) in secondList"  :key="index" @click="jumpTerritory(index,item.path)">
                                                    <img :src="item.img" alt="">
                                                    <div>
                                                        <p :class="{'second_active': index == secondSelect}" >{{item.title}}</p>
                                                        <p>{{item.content}}</p>
                                                    </div>
                                                    
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                            <router-link v-else :to="item.path">{{item.title}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- 移动端头部 -->
    <div class="mobileTopMod">
        <el-row type="flex" >
            <el-col :span="12">
                <div class="logo">
                    <img src="@/assets/image/logo.png">
                    <p>峰盛信息科技</p>
                </div>
            </el-col>
            <el-col :span="12" class="mobileTopMod_r">
                <!-- 导航栏图片 -->
                <div>
                <img src="@/assets/image/icon_menu.png" @click.stop="showNavBar">
                </div>
            </el-col>
        </el-row>
        </div>
        <!-- 移动端导航栏 -->
        <div class="navBar" id="showNav" v-show="showNavbar">
        <span class="sanjiao"></span>
        <ul>
            <li :class="{'active': index == isSelect}" v-for="(item,index) in mobileList" :key="index" @click="bindColor(index,item.path)">
                {{item.title}}
            </li>
        </ul>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            // arrList:['首页','行业方案','科学技术','关于我们'],
            arrList: [
                {
                    title: '首页',
                    path: '/index'
                },
                {
                    title: '服务领域',
                    path: ''
                },
                {
                    title: '核心服务',
                    path: '/scienTech'
                },
                {
                    title: '关于我们',
                    path: '/aboutUs'
                }
            ],
            mobileList:[
                {
                    title: '首页',
                    path: '/index'
                },
                {
                    title:'营销策划',
                    path:'/marketing'
                },
                {
                    title:'企业建站',
                    path: '/enterprise'
                },
                {
                    title:'定制开发',
                    path: '/customization'
                },
                {
                    title:'电商系统',
                    path: '/onlineStore'
                },
                {
                    title:'行业模板',
                    path: '/applet'
                },
                {
                    title: '核心服务',
                    path: '/scienTech'
                },
                {
                    title: '关于我们',
                    path: '/aboutUs'
                }
            ],
            secondList:[
                {
                    img:require('@/assets/image/second2.png'),
                    title:'营销策划',
                    content:'多元化营销工具拓展盈利来源',
                    path:'/marketing'
                },
                {
                    img:require('@/assets/image/second1.png'),
                    title:'企业建站',
                    content:'全方位打通传统互联网，快速搭建品牌形象',
                    path: '/enterprise'
                },
                {
                    img:require('@/assets/image/second5.png'),
                    title:'定制开发',
                    content:'资源整合+多元化升级+平台化运营',
                    path: '/customization'
                },
                {
                    img:require('@/assets/image/second3.png'),
                    title:'电商系统',
                    content:'专业电商系统，智能化管理高效率办公',
                    path: '/onlineStore'
                },
                {
                    img:require('@/assets/image/second4.png'),
                    title:'小程序模板',
                    content:'继微信服务号，订阅号之后，又一波微信红利',
                    path: '/applet'
                },
            ],
            //当前一级菜单
            isSelect: 0,
            //当前二级菜单
            secondSelect:null,

            showNavbar: false,
            windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
        }
    },
    mounted(){
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
            })()
        }
    },
    methods: {
        bindColor(index,path) {
            this.isSelect = index
            if(path) this.$router.push({path:path})
        },
        jumpTerritory(index,path){
            this.secondSelect = index
            this.$router.push({path:path})
        },
        // 移动端显示导航栏
        showNavBar() {
            var that = this
            this.showNavbar = !this.showNavbar
            // 给全局设置了点击事件
            document.addEventListener('click', function (e) {
                // console.log(e)
              // 样式隐藏操作
                that.showNavbar = false
            // document.getElementById('showNav').style.display = 'none';
            }, false)
        },
        home(){
            this.$router.push({path:'/'})
            this.isSelect = 0
        }
    }
}
</script>
<style>
.el-popper[x-placement^=bottom] .popper__arrow::after {
        top:0;
        border-bottom-color: rgb(3, 3, 3) !important;
    }

</style>
<style lang="less" scoped>
body{
    .buldColor {
            color:#078AFA !important; 
        }
}
// pc端css
 .topMod{
     width: 100%;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 99999;
    //  background-color: red;
        .active a {
            color:#078AFA !important; 
        }
        .second_active{
            color:#078AFA !important; 
        }
        height: 90px;
        line-height: 90px;
        background-color: #fff;
        .topMod-box{
            width: 100%;
            position: relative;
            .w-1200{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .topMod_l {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    cursor: pointer;
                    .topMod_l_logo {
                        width: 55px;
                        height: 52px;
                        line-height: 63px;
                        background-color: #fff;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .topMod_l_h {
                        font-size: 32px;
                        font-family: cursive;
                        font-weight: bold;
                        color: #222222;
                        margin-left: 20px;
                    }
                    .topMod_l_qiye {
                        height: 82px;
                        margin-left: 20px;
                    }
                }
                .topMod_c {
                    width: 394px;
                    .topMod_c_ul {
                        display: flex;
                        justify-content: space-around;
                        // .tab_li {
                        //     color:#078afa; 
                        // }
                        li:nth-child(2){
                            cursor: auto;
                            &:hover{
                                .service{
                                    display: block;
                                }
                            }
                        }
                        
                        li {
                            cursor:pointer;
                            margin: 0 10px;
                            .service{
                                display: none;
                                .secondNav{
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    height: 275px;
                                    top: 90px;
                                    left: 0;
                                    background-color: #fff;
                                    ul{
                                        display: flex;
                                        flex-wrap: wrap;
                                        width: 1110px;
                                        li{
                                            width: 350px;
                                            display: flex;
                                            margin-bottom: 40px;
                                            cursor:pointer;
                                            &:hover{
                                                div{
                                                    p:nth-child(1){
                                                        color: #078AFA;
                                                    }
                                                }
                                            }
                                            img{
                                                    width: 26px;
                                                    height: 26px;
                                                    vertical-align: middle;
                                            }
                                            div{
                                                width: 300px;
                                                margin-left: 20px;
                                                p{
                                                    font-family: Source Han Sans CN;
                                                    font-weight: 500;
                                                    color: #999999;
                                                    line-height: 25px;
                                                    text-align: left;
                                                }
                                                p:nth-child(1){
                                                    font-size: 18px;
                                                    color: #333333;
                                                }
                                                p:nth-child(2){
                                                    margin-top: 5px;
                                                    font-size: 14px;
                                                }
                                            }
                                            
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }
                .topMod_r {
                    width: 13%;
                    height: 36px;
                    line-height: 36px;
                    background: #078AFA;
                    border-radius: 18px;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 300;
                    color: #FFFFFF;
                    text-align: center;
                    margin-top: 28px;
                    margin-left:20px;
                }
            }
        }
    }

    // 移动端css
@media screen and (max-width: 1024px) {
    .topMod {
        // width: 100%;
        display: none;
        // background-color: red;
        
    }
    .active {
            color:#078AFA !important; 
        }
    .mobileTopMod {
        position:relative;
        // display: black;
        width: 100%;
        height: 44px;
        line-height: 44px;
        padding: 0 15px;
        // background-color: red;
        .logo {
            display: flex;
            img {
                width: 29px;
                height: 28px;
                margin-right: 10px;
                margin-top: 8px;
            }
            p {
                font-size: 16px;
                font-weight: 700;
                font-family: FZKai-Z03S;
            }
        }
        .mobileTopMod_r {
            text-align: right;
            img {
                width: 21px;
                height: 16px;
                margin-top: 15px;
                margin-right: 5px;
            }
        }
    }

    .navBar {
        // display: none;
        position: absolute;
        top: 44px;
        right: 0px;
        width: 133px;
        background-color: #0c0c0c;
        z-index: 3;
        ul {
            li {
                height: 48.8px;
                line-height: 48.8px;
                font-size: 14px;
                color: #fff;
            }
        }
        .sanjiao {
            position: absolute;
            top: -10px;
            right: 20px;
            width: 0;
            height: 0;
            border: 5px solid;
            border-bottom-color:rgb(3, 3, 3);
            border-right-color:transparent;
            border-top-color:transparent;
            border-left-color:transparent;
        }
    }


}

@media screen and (min-width: 1025px) {
    .mobileTopMod {
        display: none;
    }
}
</style>