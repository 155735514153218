<template>
    <div class="service">
        <ul>
            <li>
                <a href="tencent://message/?uin=422972230&Site=Sambow&Menu=yes">
                            <i class="el-icon-chat-dot-round"></i>
                            <p>在线联系QQ</p>
                </a>
            </li>
            <li>
                <div>
                    <i class="el-icon-phone-outline"></i>
                    <p>电话联系</p>
                    <p>15902055173</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.service{
    position: fixed;
    bottom: 5%;
    right: 5px;
    z-index: 99999;
    li{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        height: 80px;
        background-color: #078AFA;
        margin-bottom: 5px;
        cursor: pointer;
        a{
            width: 100%;
            height: 100%;
            padding-top: 18px;
        }  
        i{
            color: #fff;
            font-size: 24px;
        }
        p{
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}
@media screen and (max-width: 1024px) {
    .service{
        display: none;
    }
}
</style>