<template>
  <div id="app">
    <!-- 顶部栏模块 -->
        <topMod/>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <onlineService/>
    <footerMod/>
  </div>
</template>
<script>
import topMod from '@/components/topMod'
import footerMod from '@/components/footerMod'
import onlineService from '@/components/onlineService'
export default {
  components: {
        topMod,
        footerMod,
        onlineService
    }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body{
  padding-right: 0 !important;
}
</style>
