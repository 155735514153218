<template>
<div>
  <!-- PC端底部栏 -->
  <div class="footer">
    <div class="w-1200">
      <div class="footer_t">
        <div class="footer_t_l">
          <img src="@/assets/image/logo.png" alt="">
          <p>峰盛信息科技</p>
        </div>
        <div class="footer_t_c">
          <p>联系我们</p>
          <p><i class="el-icon-phone"></i>&nbsp;&nbsp;15902055173</p>
          <p> <i class="el-icon-message"></i>&nbsp;&nbsp;hxf@fengshenginfo.com</p>
          <p> <i class="el-icon-location"></i>&nbsp;&nbsp;广州市天河区灵山东路5号大厦501-6房</p>
        </div>
        <div class="footer_t_r">
          <img src="@/assets/image/QRcode.png" alt="">
          <p>扫一扫 联系我们</p>
        </div>
      </div>
      <div class="border"></div>
      <div class="footer_b">
        <p>2021© | 广州峰盛信息科技有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021079449号</a> | 版权声明</p>
      </div>
    </div>
  </div>
  <!-- 移动端底部栏 -->
    <div class="mobile_footer">
      <div class="footer_t">
        <div class="footer_t_c">
          <p>联系我们</p>
          <p><i class="el-icon-phone"></i>&nbsp;&nbsp;15902055173</p>
          <p> <i class="el-icon-message"></i>&nbsp;&nbsp;hxf@fengshenginfo.com</p>
          <p> <i class="el-icon-location"></i>&nbsp;&nbsp;广州市天河区灵山东路5号大厦501-6房</p>
        </div>
        <div class="footer_t_r">
          <img src="@/assets/image/QRcode.png" alt="">
          <p>扫一扫联系我们</p>
        </div>
      </div>
      <div class="border"></div>
      <div class="footer_b">
        <p>2021© | 广州峰盛信息科技有限公司 | <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021079449号</a> | 版权声明</p>
      </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

    .footer {
        height: 261px;
        padding: 0 80px;
        background-color: #323232;
        .footer_t {
          display: flex;
          justify-content: space-between;
          height: 209px;
          // background-color: pink;
          .footer_t_l {
            display: flex;
            margin: 82px 0 93px;
            img {
            width: 55px;
            height: 52px;
            }
            p {
               margin-left: 20px;
              font-size: 34px;
              color: #FEFEFE;
            }
          }
          .footer_t_c {
            text-align: left;
            color: #fff;
            font-size: 14px;
            padding-top: 34px;
            p {
              line-height: 34px;

              &:nth-child(1) {
                font-size: 16px;
              }
            }
          }
          .footer_t_r {
            padding-top: 34px;
            img {
              width: 128px;
              height: 128px;
            }
            p {
              font-size: 14px;
              color: #D7D7D7;
            }
          }
        }
        .border {
          height: 1px;
          background: radial-gradient(#4d4d4d 24%, #343434 60%);
        }
        .footer_b {
          height: 51px;
          line-height: 51px;
          color: #D7D7D7;
          font-size: 12px;
          a{
            color: #D7D7D7;
          }
        }
    }

    @media screen and (max-width: 1024px) {
      .footer {
        display: none;
      }
      .mobile_footer {
        background-color: #323232;
        .footer_t {
          display: flex;
          justify-content: space-around;
          padding-top: 15px;
          .footer_t_c {
            text-align: center;
            p {
              font-size: 10px; 
              -webkit-transform:scale(0.8);
              line-height: 24px;
              color: #fff;
              text-align: left;
              width: 293px;
              &:nth-child(1) {
                font-size: 14px;
                // color: red;
              }
            }

          }

          .footer_t_r {
            img {
              width: 64px;
              height: 64px;
            }
            p {
              width: 100px;
              text-align: center;
              color: #fff;
              font-size: 8px; -webkit-transform:scale(0.8);
            }
          }
        }

          .border {
              height: 1px;
              background: radial-gradient(#4d4d4d 24%, #343434 60%);
          }
          .footer_b {
              height: 51px;
              line-height: 51px;
              color: #D7D7D7;
              font-size: 8px;-webkit-transform:scale(0.8);
              a{
                color: #D7D7D7;
              }
          }
      }
    }

    @media screen and (min-width: 1025px) {
      .mobile_footer {
        display: none;
      }
    }
    @media screen and (width: 320px) {
      .mobile_footer {
        .footer_t {
          p {
            width: 152px !important;
          }
        }
        .footer_t_r {
          margin-top: 15px;
        }
      }
    }
</style>